.line {
  display: flex;
  width: 100%;
}
.line-left {
  width: 20%;
  background-color: #bfd2f8;
  height: 6px;
}

.line-center {
  width: 60%;
  background-color: #1f2b6c;
  height: 6px;
}
.line-right {
  width: 20%;
  background-color: #159eec;
  height: 6px;
}
.strengths {
  width: 100%;
  height: 563.47px;
  background-color: #1d4499;
  font-style: normal;
  margin-top: 40px;
  padding-top: 95px;
}

.strengths-left {
}

.strengths-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #bfd2f8;
  opacity: 0.87;
  padding: 12px 0;
}

.strengths-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #fcfefe;
  padding: 12px 0;
  width: 491px;
}

.strengths-des {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #bfd2f8;
  padding: 12px 0;
  width: 491px;
}

.strengths-right {
  width: 100%;
}

.strengths-item {
  background-color: #ffffff;
  width: 100%;
  height: 119.53px;
  position: relative;
  margin: 10px 0;
}
.strengths-item-logo > i {
  font-size: 30px;
  color: #159eec;
}
.strengths-item-logo {
  padding-top: 40px;
}
.border {
  background-color: #dfdfe2;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  /* position: absolute;
    top: 30px;
    left: 32px; */
}
.strengths-icon {
  margin-top: 10px;
  margin-left: 10px;
}
.strengths-item-text {
  padding-top: 25px;
}

.strengths-item-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  width: 194px;
}

.strengths-item-des {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 327.18px;
  color: #5d5f6d;
}
/* Table và moblie */
@media (max-width: 1023px) {
  .hide-on-table-mobile {
    display: none;
  }
}
/* Table*/
@media (min-width: 740px) and (max-width: 1023px) {
  .hide-on-table {
    display: none;
  }
}
/* Mobile*/
@media (max-width: 739px) {
  .hide-on-mobile {
    display: none;
  }
  .strengths {
    height: auto;
    padding: 10px 20px;
  }
  .strengths-item-text {
    padding-left: 25px;
  }
  .strengths-item-title {
    font-size: 13px;
  }
  .strengths-item-des {
    font-size: 13px;
    width: 223.382px;
  }
  .strengths-item-logo {
    padding-top: 35px;
  }
  .strengths-icon {
    font-size: 10px;
  }
  .border {
    padding-bottom: 10px;
    padding-right: 10px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  line-height: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  box-sizing: border-box;
}
.IntroPagegeneral-header {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #232536;
  padding: 15px 0;
}

.IntroPagegeneral-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #232536;
  width: 549px;
  padding: 15px 0;
}

.IntroPagegeneral-des {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232536;
  opacity: 0.87;
  padding: 15px 0;
}
.IntroPagesquare {
  width: 28px;
  height: 28px;
  background: #1d4499;
  transform: rotate(-90deg);
  margin-bottom: 17px;
}

.IntroPageslider {
  width: 100%;
  background-color: #f2f2f2;
  font-style: normal;
  color: #1f2b6c;
}
.IntroPageslider > img {
  width: 100%;
  object-fit: contain;
  background-color: #f2f2f2;
}
.IntroPageslider-header {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: -180px;
}

.IntroPageslider-title {
  margin-top: -150px;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}
.IntroPageline {
  display: flex;
  width: 100%;
}
.IntroPageline-left {
  width: 20%;
  background-color: #bfd2f8;
  height: 6px;
}

.IntroPageline-center {
  width: 60%;
  background-color: #1f2b6c;
  height: 6px;
}
.IntroPageline-right {
  width: 20%;
  background-color: #159eec;
  height: 6px;
}
.IntroPageintro {
  padding-top: 70px;
}

.IntroPageintro-header {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.87;
  margin: 25px 0;
}

.IntroPageintro-title {
  width: 908px;
  height: 116px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #232536;
  margin: 25px 0;
}

.IntroPageintro-des {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232536;
  opacity: 0.87;
  margin-bottom: 20px;
}

.IntroPageintro-items {
  margin-top: 20px;
  width: 100%;
}
.IntroPageintro-item {
  width: 100%;
}
.IntroPageintro-img > img {
  width: 100%;
}
.IntroPagestory {
  font-style: normal;
  padding-top: 70px;
}

.IntroPagestory-left {
}
.IntroPagestory-header {
  width: 549px;
}

.IntroPagestory-title {
  width: 549px;
}

.IntroPagestory-des {
  width: 549px;
}

.IntroPagestory-right {
  background: rgba(189, 209, 248, 0.63);
  width: 573px;
  height: 368px;
}
.IntroPagestory-number {
  width: 100%;
  height: 100%;
}
.IntroPagenumber-item {
  font-style: normal;
  padding-top: 60px;
}
.IntroPagenumber-item:nth-child(1) {
  margin-left: 100px;
}
.IntroPagenumber-item:nth-child(3) {
  margin-left: 100px;
}
.IntroPagenumber-item-numbers {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #232536;
}

.IntroPagenumber-item-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.IntroPagevision {
  padding-top: 70px;
}

.IntroPagevision-header {
  width: 100%;
  text-transform: uppercase;
}

.IntroPagevision-title {
  width: 739px;
}

.IntroPagevision-des {
  width: 832px;
  text-align: left;
}
.IntroPagevision-img {
  width: 100%;
  padding: 20px 0;
}

.IntroPagevision-img > img {
  width: 100%;
  object-fit: cover;
}
.IntroPagemission {
  padding-top: 70px;
  padding-bottom: 50px;
}

.IntroPagemission-header {
  width: 100%;
}

.IntroPagemission-title {
  width: 739px;
}

.IntroPagemission-des {
  width: 832px;
  text-align: left;
}
.IntroPagemission-img {
  width: 100%;
  padding: 20px 0;
}

.IntroPagemission-img > img {
  width: 100%;

  object-fit: cover;
}
.IntroPageproduct {
  background: #d4e2fa;
  padding: 50px 0;
}

.IntroPageproduct-header {
  width: 824px;
  padding: 40px 0;
}

.IntroPageproduct-title {
  width: 100%;
  background: #1d459a;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.87;
  text-align: center;
}

.IntroPageproduct-items {
  padding: 20px 0;
  margin: 20px 0;
}

/* .IntroPageproduct-item:hover {
  transform: scale(1.04);
  transition: 0.5s;
} */
.IntroPageproduct-item {
  margin: 20px 0;
  padding: 10px 0;
}
.IntroPageproduct-item__img > img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.IntroPageproduct-item__img::before {
  content: "";
  display: block;
  width: 397px;
  height: 299px;
  position: absolute;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #000000 100%);
}

.IntroPageproduct-item_title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  margin-top: -150px;
  margin-left: 30px;
  position: relative;
  z-index: 1;
}
.IntroPageproduct-items:nth-child(3) .IntroPageproduct-item_title {
  margin-top: -90px;
}
.IntroPageproduct-item_des {
  color: #ffffff;
  margin-left: 30px;
  opacity: 0.6;
  position: relative;
  z-index: 1;
}
.IntroPageproduct-footer {
  background: #1d459a;
  height: 143px;
}
.IntroPageproduct-footer-text {
  width: 700px;
  margin-left: 400px;
  margin-top: 30px;
  text-align: center;
  color: #ffffff;
}
.IntroPageproud {
  padding-top: 51px;
  padding-bottom: 21px;
  font-style: normal;
  background: #f2f2f2;
  color: #1d459a;
  height: auto;
}

.IntroPageproud-header {
  font-weight: 400;
  font-size: 16px;
}

.IntroPageproud-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  padding: 10px 0;
}

.IntroPageproud-des {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: 35px;
  width: 573px;
}

.IntroPageproud-items {
  padding-top: 30px;
  padding-bottom: 70px;
}

.IntroPageproud-item__img > img {
  width: 100%;
  object-fit: contain;
  background-color: red;
}
.IntroPagecommitment {
  font-style: normal;
  /* height: 680px; */
  padding-bottom: 30px;
}
.IntroPagecommitment-line {
  background: #1d459a;
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 3.1px;
}

.IntroPagecommitment-title {
  font-size: 32px;
  background-color: #d4e2fa;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 30px 0;
  text-align: center;
}

.IntroPagecommitment-des {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 50px 0;
  padding-left: 240px;
  width: 1100px;
}
.IntroPagecommitment-des_item {
  display: flex;
  align-items: center;
}
.IntroPagecommitment-des_item_icon > img {
  margin-right: 10px;
  margin-top: 6px;
}
.IntroPagecommitment-des_item > p {
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px 20px;
  border: 0.5px solid #1d459a;
  background: rgba(217, 217, 217, 0);
  width: 1100px;
}
/* Table và moblie */
@media (max-width: 1023px) {
  .hide-on-table-mobile {
    display: none;
  }
}
/* Table*/
@media (min-width: 740px) and (max-width: 1023px) {
  .hide-on-table {
    display: none;
  }
}
/* Mobile*/
@media (max-width: 739px) {
  .hide-on-mobile {
    display: none;
  }
  .IntroPageintro {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .IntroPageintro-header {
    font-size: 13px;
    color: #1d4499;
    margin: 10px 0;
  }
  .IntroPageintro-title {
    font-size: 15px;
    margin: 10px 0;
    line-height: 20px;
    height: auto;
  }
  .IntroPageintro-des {
    font-size: 13px;
    margin: 10px 0;
  }
  .IntroPagevision {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .IntroPagegeneral-header {
    font-size: 13px;
    color: #1d4499;
    padding: 0;
    margin: 10px 0;
  }
  .IntroPagegeneral-title {
    font-size: 15px;
    margin: 10px 0;
    line-height: 20px;
    height: auto;
    padding: 0;
  }
  .IntroPagegeneral-des {
    font-size: 13px;
    padding: 0;
    margin: 10px 0;
  }
  .IntroPagevision-img > img {
    width: 100%;
    object-fit: cover;
  }
  .IntroPagemission {
    padding-left: 20px;
    padding-right: 20px;
  }
  .IntroPageproud {
    height: auto;
  }
  .IntroPageproud-header {
    font-size: 13px;
    padding: 0;
    margin: 10px 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .IntroPageproud-title {
    font-size: 15px;
    margin: 10px 0;
    line-height: 20px;
    height: auto;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .IntroPageproud-des {
    font-size: 13px;
    padding: 0;
    margin: 10px 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 95vw;
  }
  .IntroPageproud-items {
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .IntroPagemission {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .IntroPageproduct {
  }

  .IntroPageproduct-header {
    width: 100vw;
    padding: 0px 10px;
  }

  .IntroPageproduct-title {
    width: 100%;
    background: #1d459a;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.87;
    text-align: center;
    padding: 20px 0;
  }

  .IntroPageproduct-items {
    margin: 0;
    padding: 0;
  }
  .IntroPageproduct-item {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 156px;
    padding: 10px 0;
  }
  .IntroPageproduct-item__img > img {
    width: 100%;
    height: 140px;
    object-fit: contain;
  }
  .IntroPageproduct-item__img::before {
    content: "";
    display: block;
    width: 188px;
    height: 140px;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      #000000 100%
    );
  }
  .IntroPageproduct-item_title {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    margin-top: -51px !important;
  }

  .IntroPageproduct-item_des {
    display: none;
  }
  .IntroPageproduct-footer {
    background: #1d459a;
    height: 143px;
  }
  .IntroPageproduct-footer-text {
    width: 700px;
    margin-left: 400px;
    margin-top: 30px;
    text-align: center;
    color: #ffffff;
  }
  .IntroPagecommitment {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .IntroPagecommitment-title {
    font-size: 20px;
    padding: 20px 0;
  }

  .IntroPagecommitment-des {
    width: 100vw;
    padding-left: 0;
  }
  .IntroPagecommitment-des_item {
  }
  .IntroPagecommitment-des_item_icon > img {
    width: 45px;
    height: 26px;
  }
  .IntroPagecommitment-des_item > p {
    font-size: 10px;
    width: 75vw;
    line-height: 15px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}
html {
  font-size: 62.5%;
  line-height: 1.6rem;
  font-style: normal;
  box-sizing: border-box;
  font-family: "Roboto";
}
.slider {
  width: 100%;
  height: 526px;
  background-color: #f2f2f2;
}
.slider-text {
  margin-top: -450px;
}
.slider-title-header {
  font-style: normal;
  font-weight: 1000;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #159eec;
  padding-bottom: 20px;
}

.slider-title {
  font-style: normal;
  font-weight: 1000;
  font-size: 40px;
  line-height: 47px;
  width: 613px;
  color: #1d4499;
  padding-bottom: 30px;
}

.slider-des {
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 550px;
  color: rgb(0, 0, 0);
  font-family: "Roboto";
}

.slider-img > img {
  width: 100%;
  height: 526px;
  object-fit: cover;
}

.slider-button > button {
  background: #bfd2f8;
  border-radius: 50px;
  border: none;
  transition: 0.5s;
  padding: 13px 35px;
  color: #1f2b6c;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 19px; */
}
.slider-button > button:hover {
  background-color: #1d4499;
  color: white;
}

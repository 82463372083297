line {
  display: flex;
  width: 100%;
}
.line-left {
  width: 20%;
  background-color: #bfd2f8;
  height: 6px;
}

.line-center {
  width: 60%;
  background-color: #1f2b6c;
  height: 6px;
}
.line-right {
  width: 20%;
  background-color: #159eec;
  height: 6px;
}

.service {
  width: 100%;
  height: 864px;
  font-style: normal;
  padding-top: 67px;
}

.service-header {
  padding-top: 65px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #159eec;
  padding: 10px 0;
}

.service-title {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #1f2b6c;
  text-align: center;
  padding-bottom: 25px;
}

.service-items {
  padding-top: 25px;
}
.service-items:hover {
  transform: scale(1.02);
  transition: 0.5s;
}
.service-item {
  background: #1d4499;
  border-radius: 9px;
  width: 100%;
  height: 297px;
  padding: 25px;
  color: #fcfefe;
}

.service-item__logo {
  font-size: 60px;
  padding-bottom: 25px;
}

.service-item__title {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 18px;
  line-height: 140%;
}

.service-item__des {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 30px;
  line-height: 140%;
}

.service-item__more {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  padding-top: 10px;
  line-height: 140%;
}
.color__private {
  background-color: #bfd2f8;
  color: #1f2b6c;
}
.service-item__more > i {
  color: #159eec;
  padding: 5px;
}
/* Table và moblie */
@media (max-width: 1023px) {
  .hide-on-table-mobile {
    display: none;
  }
}
/* Table*/
@media (min-width: 740px) and (max-width: 1023px) {
  .hide-on-table {
    display: none;
  }
}
/* Mobile*/
@media (max-width: 739px) {
  .hide-on-mobile {
    display: none;
  }

}